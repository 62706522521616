:root {
  --s-textcolor: #000000;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #8DC585;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'ff-meta-web-pro', sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #8DC585;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
@media (max-width: 1023px) {
  .cb-album .cb-image-container {
    position: absolute;
    height: 70%;
  }
}
@media (max-width: 767px) {
  .cb-album .cb-image-container {
    position: absolute;
    height: 100%;
  }
}
div.load {
  background: none !important;
}
.section:not(.section--darkgreen) div.load a.load {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 35px;
  position: relative;
}
.section:not(.section--darkgreen) div.load a.load:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 21px;
  top: 1px;
  left: 4px;
  background-size: 13px;
  background-position: 0 -15px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section:not(.section--darkgreen) div.load a.load:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
  background-color: #8DC585;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section:not(.section--darkgreen) div.load a.load:hover,
.section:not(.section--darkgreen) div.load a.load:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section:not(.section--darkgreen) div.load a.load {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) div.load a.load:after {
    content: '';
    background-size: 13px;
    background-position: 0 -16px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) div.load a.load:active,
  .section:not(.section--darkgreen) div.load a.load:hover,
  .section:not(.section--darkgreen) div.load a.load:focus {
    color: #000000;
  }
  .section:not(.section--darkgreen) div.load a.load:active:after,
  .section:not(.section--darkgreen) div.load a.load:hover:after,
  .section:not(.section--darkgreen) div.load a.load:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px -14px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-black.svg');
  }
  .section:not(.section--darkgreen) div.load a.load:active:before,
  .section:not(.section--darkgreen) div.load a.load:hover:before,
  .section:not(.section--darkgreen) div.load a.load:focus:before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #000000;
  }
}
.section.section--darkgreen .side div.load a.load {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 35px;
  position: relative;
}
.section.section--darkgreen .side div.load a.load:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 21px;
  top: 1px;
  left: 4px;
  background-size: 13px;
  background-position: 0 -15px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section.section--darkgreen .side div.load a.load:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
  background-color: #8DC585;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section.section--darkgreen .side div.load a.load:hover,
.section.section--darkgreen .side div.load a.load:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section.section--darkgreen .side div.load a.load {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side div.load a.load:after {
    content: '';
    background-size: 13px;
    background-position: 0 -16px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side div.load a.load:active,
  .section.section--darkgreen .side div.load a.load:hover,
  .section.section--darkgreen .side div.load a.load:focus {
    color: #000000;
  }
  .section.section--darkgreen .side div.load a.load:active:after,
  .section.section--darkgreen .side div.load a.load:hover:after,
  .section.section--darkgreen .side div.load a.load:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px -14px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-black.svg');
  }
  .section.section--darkgreen .side div.load a.load:active:before,
  .section.section--darkgreen .side div.load a.load:hover:before,
  .section.section--darkgreen .side div.load a.load:focus:before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #000000;
  }
}
.section--three .area .seam div.load a.load,
.section--four .area .seam div.load a.load,
.section--darkgreen .area:not(.side) div.load a.load {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 35px;
  position: relative;
}
.section--three .area .seam div.load a.load:after,
.section--four .area .seam div.load a.load:after,
.section--darkgreen .area:not(.side) div.load a.load:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 21px;
  top: 1px;
  left: 4px;
  background-size: 13px;
  background-position: 0 -15px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--three .area .seam div.load a.load:before,
.section--four .area .seam div.load a.load:before,
.section--darkgreen .area:not(.side) div.load a.load:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
  background-color: #8DC585;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--three .area .seam div.load a.load:hover,
.section--four .area .seam div.load a.load:hover,
.section--darkgreen .area:not(.side) div.load a.load:hover,
.section--three .area .seam div.load a.load:focus,
.section--four .area .seam div.load a.load:focus,
.section--darkgreen .area:not(.side) div.load a.load:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--three .area .seam div.load a.load,
  .section--four .area .seam div.load a.load,
  .section--darkgreen .area:not(.side) div.load a.load {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam div.load a.load:after,
  .section--four .area .seam div.load a.load:after,
  .section--darkgreen .area:not(.side) div.load a.load:after {
    content: '';
    background-size: 13px;
    background-position: 0 -16px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam div.load a.load:active,
  .section--four .area .seam div.load a.load:active,
  .section--darkgreen .area:not(.side) div.load a.load:active,
  .section--three .area .seam div.load a.load:hover,
  .section--four .area .seam div.load a.load:hover,
  .section--darkgreen .area:not(.side) div.load a.load:hover,
  .section--three .area .seam div.load a.load:focus,
  .section--four .area .seam div.load a.load:focus,
  .section--darkgreen .area:not(.side) div.load a.load:focus {
    color: #FFFFFF;
  }
  .section--three .area .seam div.load a.load:active:after,
  .section--four .area .seam div.load a.load:active:after,
  .section--darkgreen .area:not(.side) div.load a.load:active:after,
  .section--three .area .seam div.load a.load:hover:after,
  .section--four .area .seam div.load a.load:hover:after,
  .section--darkgreen .area:not(.side) div.load a.load:hover:after,
  .section--three .area .seam div.load a.load:focus:after,
  .section--four .area .seam div.load a.load:focus:after,
  .section--darkgreen .area:not(.side) div.load a.load:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px -14px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-white.svg');
  }
  .section--three .area .seam div.load a.load:active:before,
  .section--four .area .seam div.load a.load:active:before,
  .section--darkgreen .area:not(.side) div.load a.load:active:before,
  .section--three .area .seam div.load a.load:hover:before,
  .section--four .area .seam div.load a.load:hover:before,
  .section--darkgreen .area:not(.side) div.load a.load:hover:before,
  .section--three .area .seam div.load a.load:focus:before,
  .section--four .area .seam div.load a.load:focus:before,
  .section--darkgreen .area:not(.side) div.load a.load:focus:before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #FFFFFF;
  }
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
  color: var(--s-textcolor);
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 100%;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 32.09876543%;
  position: relative;
}
.cb-layout2 #slides {
  height: 100% !important;
  padding-bottom: 0;
}
.cb-layout1 #slides {
  padding-bottom: 76.99901283%;
}
@media (max-width: 1023px) {
  .cb-layout1 #slides {
    padding-bottom: 70.08884501%;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: block;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #8DC585;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 50px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 767px) {
  .cb-index-all {
    bottom: 20px;
  }
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 8px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 18px;
  height: 18px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 18px;
  transition: all 0.4s;
}
@media (max-width: 767px) {
  .cb-index-all a {
    width: 15px;
    height: 15px;
    border-radius: 15px;
  }
}
.cb-index-all td.this a {
  background: #006C43;
}
.cb-index-some {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'ff-meta-web-pro', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #8DC585;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #8DC585;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  #disp .foot input,
  #disp .foot a {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  #disp .foot input:active,
  #disp .foot a:active,
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'ff-meta-web-pro', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-weight: bold;
  color: var(--s-textcolor);
  --s-textcolor: #006C43;
}
.section--three .area .seam .unit.form .name,
.section--darkgreen .unit.form .name {
  --s-textcolor: #FFFFFF;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  min-height: 50px;
  padding: 5px 10px;
  -webkit-appearance: none;
  border: 2px solid #CDE4C4;
}
@media (max-width: 767px) {
  .unit.form input.text,
  .unit.form textarea {
    min-height: 40px;
  }
}
.unit.form input.text:focus-within,
.unit.form textarea:focus-within {
  border-color: #006C43;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 13px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'ff-meta-web-pro', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background: #FFFFFF;
  height: 50px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .unit.form input.submit {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .unit.form input.submit:active,
  .unit.form input.submit:hover,
  .unit.form input.submit:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .two-step-verification-container a {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .two-step-verification-container a:active,
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: var(--s-textcolor);
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-bottom: 2px solid rgba(0, 108, 67, 0.2);
}
.section--three .area .seam .table tr,
.section--four .area .seam .table tr,
.section--darkgreen .area:not(.side) .table tr {
  border-color: rgba(205, 228, 196, 0.2);
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 15px 40px 15px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-size: 28px;
  line-height: 1.28571429;
}
.table td {
  padding: 15px 40px 15px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    display: block;
  }
  .part--table .table .table-head,
  .part--table .table .table-foot {
    display: none;
  }
  .part--table .table .table-body {
    display: block;
    width: 100%;
  }
  .part--table .table tr {
    float: left;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 5px 10px;
    border-bottom: 2px solid rgba(0, 108, 67, 0.2);
    margin-top: 9px;
  }
  .section--three .area .seam .part--table .table tr,
  .section--four .area .seam .part--table .table tr,
  .section--darkgreen .area:not(.side) .part--table .table tr {
    border-color: #FFFFFF;
  }
  .table-body .part--table .table tr:first-child {
    margin-top: 0;
  }
  .part--table .table .table-body tr:first-child {
    margin-top: 0;
  }
  .part--table .table td {
    float: left;
    display: block;
    width: 100%;
    padding: 5px 0;
  }
  .part--table .table.table--headline td {
    position: relative;
    box-sizing: border-box;
    padding-left: 52%;
  }
  .part--table .table.table--headline td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    width: 48%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 42px;
  margin-top: 16px;
  margin-bottom: 20px;
}
.cb-layout1:not(.cb-toggle-target-active) .wrapper:not(.cb-scroll-triggered--active) #home {
  content: url(/images/logo-heimetblick-white-2022-02-07.svg);
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.cb-layout2 #head,
.cb-layout3 #head {
  margin-top: var(--spaceTotal);
}
.cb-layout4 #head,
.cb-layout5 #head {
  margin-top: var(--spaceTotal);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'ff-meta-web-pro', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--s-textcolor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
body:not(.cb-layout1) .wrapper {
  padding-top: 78px;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 84%;
  width: 1278px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--intro--one .desk,
.section--intro--two .desk,
.section--multimood .desk,
.section--header .desk {
  width: 1620px;
}
.section--six .desk {
  width: 100%;
  max-width: 100%;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  z-index: 1999;
}
body:not(.cb-layout1) .section--header {
  background-color: #FFFFFF;
}
.cb-scroll-triggered--active .section--header {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
}
body.cb-toggle-target-active .section--header {
  box-shadow: unset;
}
.container {
  float: left;
  position: relative;
}
.container--head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.container--nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.section--header #toplinks {
  float: right;
  margin-bottom: 30px;
}
@media (max-width: 1023px) {
  .section--header #toplinks {
    display: none;
  }
}
.section--header #toplinks .meta {
  font-weight: normal;
  color: #000000;
  margin-right: 30px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--header #toplinks .meta:last-child {
  margin-right: 0;
}
.section--header #toplinks .meta:hover,
.section--header #toplinks .meta:focus {
  color: #8DC585;
}
.mobile-navigation #toplinks {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobile-navigation #toplinks .meta {
  font-weight: normal;
  color: #000000;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.section--darkgreen {
  background-color: #006C43;
  --s-textcolor: #FFFFFF;
}
#view:not(.areaSeveteen-empy) .section--areaSeveteen.section--darkgreen {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--softgreen {
  background-color: #EEF6EA;
}
.section--intro {
  z-index: 2;
}
.container--intro {
  padding-top: 98px;
  box-sizing: border-box;
  width: 100%;
}
.container--intro .multimood {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
}
.container--intro .content {
  float: left;
  width: 100%;
}
.container--intro-two {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
}
.container--intro-two .multimood {
  float: left;
  width: 100%;
  height: 220px;
}
.container--intro-four {
  width: 100%;
}
.column {
  float: left;
  width: 100%;
  position: relative;
}
.section--intro--one {
  isolation: isolate;
}
.section--intro--one:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 270px;
  background-color: #EEF6EA;
  z-index: -2;
}
.section--multimood {
  isolation: isolate;
}
.section--multimood:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: #EEF6EA;
  z-index: -2;
}
.breadcrump {
  float: left;
  width: 100%;
  margin-bottom: var(--spaceTotal);
}
.cb-layout4 .breadcrump,
.cb-layout5 .breadcrump,
.cb-layout6 .breadcrump {
  margin-top: var(--spaceTotal);
}
.breadcrump a {
  font-size: 14px;
  line-height: 1.85714286;
  display: inline-block;
  font-weight: bold;
}
.breadcrump .here {
  color: #000000;
  font-weight: normal;
}
@media (max-width: 1023px) {
  .breadcrump {
    display: none;
  }
}
.breadcrump--arrow {
  float: none;
  width: 15px;
  margin-right: 10px;
  margin-left: 7px;
  margin-bottom: 2px;
}
.section--footer {
  flex-shrink: 0;
  isolation: isolate;
  margin-top: var(--spaceTotal);
}
.footarea {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.footpart {
  float: left;
  width: 100%;
  position: relative;
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .footpart--flex {
    margin-top: -7px;
  }
}
@media (min-width: 1024px) {
  .footpart--flex a:hover,
  .footpart--flex a:focus {
    color: #FFFFFF;
  }
}
.footlogo {
  float: left;
  width: 52px;
  margin-top: 30px;
}
.foottitle {
  margin-bottom: 10px;
}
.contact,
.vcard {
  float: left;
  width: 100%;
}
.contact a,
.vcard a {
  display: inline-block;
}
@media (min-width: 1024px) {
  .contact a:hover,
  .vcard a:hover,
  .contact a:focus,
  .vcard a:focus {
    color: #FFFFFF;
  }
}
.contact {
  margin-top: 10px;
}
.contact .tel {
  margin-bottom: 5px;
}
.service_googlemap,
.service_downloads {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
  margin-top: 30px;
}
.service_googlemap:hover,
.service_downloads:hover,
.service_googlemap:focus,
.service_downloads:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .service_googlemap,
  .service_downloads {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .service_googlemap:active,
  .service_downloads:active,
  .service_googlemap:hover,
  .service_downloads:hover,
  .service_googlemap:focus,
  .service_downloads:focus {
    color: #FFFFFF;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-white.svg');
  }
}
@media (max-width: 1023px) {
  .service_googlemap,
  .service_downloads {
    margin-top: 20px;
  }
}
.footstripe {
  float: left;
  width: 100%;
  margin-top: 50px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
.footstripe .footarea {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .footstripe {
    margin-top: 30px;
  }
}
.toplink {
  position: absolute;
  width: 30px;
  height: 50px;
  cursor: pointer;
  z-index: 100;
  top: 60px;
  right: 8vw;
}
.toplink:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 38px;
  background-size: 22px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-scrollup.svg);
}
.toplink:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 35px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-scrollup.svg);
}
@media (min-width: 1024px) {
  .toplink {
    width: 50px;
    height: 90px;
  }
  .toplink:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 63px;
    background-size: 22px;
    background-position: 50% calc(100% + 15px);
    background-repeat: no-repeat;
    background-image: url(/images/icon-arrow-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .toplink:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 40px;
    height: 46px;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/icon-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
    transform-origin: 22%;
  }
  .toplink:hover:after,
  .toplink:focus:after {
    background-position: 50% 100%;
  }
  .toplink:hover:before,
  .toplink:focus:before {
    transform: rotate(-160deg);
  }
}
@media (max-width: 1023px) {
  .toplink {
    top: 35px;
    right: 45px;
  }
}
.downlink {
  position: absolute;
  width: 30px;
  height: 50px;
  cursor: pointer;
  z-index: 100;
  bottom: 30px;
  left: 0;
  transform: rotate(180deg);
}
.downlink:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 38px;
  background-size: 22px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-scrollup.svg);
}
.downlink:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 35px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-scrollup.svg);
}
@media (min-width: 1024px) {
  .downlink {
    width: 50px;
    height: 90px;
  }
  .downlink:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 63px;
    background-size: 22px;
    background-position: 50% calc(100% + 15px);
    background-repeat: no-repeat;
    background-image: url(/images/icon-arrow-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .downlink:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 40px;
    height: 46px;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/icon-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
    transform-origin: 22%;
  }
  .downlink:hover:after,
  .downlink:focus:after {
    background-position: 50% 100%;
  }
  .downlink:hover:before,
  .downlink:focus:before {
    transform: rotate(-160deg);
  }
}
@media (max-width: 1023px) {
  .downlink {
    display: none;
  }
}
.backlink {
  float: left;
  position: relative;
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 48px;
  margin-top: var(--spaceTotal);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.backlink:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 40px;
  height: 14px;
  background-size: 35px;
  background-position: calc(100% + 10px) 0;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-left-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.backlink:hover,
.backlink:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .backlink:active,
  .backlink:hover,
  .backlink:focus {
    color: #000000;
  }
  .backlink:active:after,
  .backlink:hover:after,
  .backlink:focus:after {
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-left-black.svg');
  }
}
.sep {
  margin: 0 5px;
}
.designelement {
  float: left;
  position: absolute;
  z-index: -1;
}
.designelement--footer {
  bottom: 0;
  right: 0;
  width: 112px;
}
.designelement--lay1-top {
  width: 142px;
  bottom: 90px;
  right: -35px;
  z-index: 11;
}
.designelement--lay1-bot {
  width: 155px;
  bottom: -107px;
  left: -48px;
  z-index: 11;
}
.designelement--lay2-top {
  width: 142px;
  top: 120px;
  right: -35px;
  z-index: 11;
}
.designelement--lay2-bot {
  width: 155px;
  bottom: -107px;
  left: -48px;
  z-index: 11;
}
@media (max-width: 1023px) {
  .designelement--lay2-bot {
    display: none;
  }
}
.designelement--lay4-bot {
  width: 155px;
  bottom: -107px;
  left: -48px;
  z-index: 11;
}
@media (max-width: 1023px) {
  .designelement--lay4-bot {
    display: none;
  }
}
.websiteby {
  float: left;
  margin-right: 5px;
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100% - 80px);
  width: 400px;
  z-index: 2100;
  font-family: 'ff-meta-web-pro', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--s-textcolor);
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    left: 8vw;
    bottom: 8vw;
    max-width: 84vw;
    font-size: 15px;
    line-height: 1.46666667;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 30px 30px 30px;
}
.cb-cookie-warning__button {
  float: left;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  display: none;
}
#cb-cookie-warning__button--accept {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
  font-size: 18px;
  line-height: 1.44444444;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  #cb-cookie-warning__button--accept {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  #cb-cookie-warning__button--accept:active,
  #cb-cookie-warning__button--accept:hover,
  #cb-cookie-warning__button--accept:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
a {
  color: #8DC585;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (min-width: 1024px) {
  a:hover,
  a:focus {
    color: #000000;
  }
}
.section--darkgreen .part.text .open:hover,
.section--darkgreen .part.text .open:focus {
  color: #FFFFFF;
}
.section:not(.section--darkgreen) .part.link:not(.elem) .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section:not(.section--darkgreen) .part.link:not(.elem) .open:hover,
.section:not(.section--darkgreen) .part.link:not(.elem) .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section:not(.section--darkgreen) .part.link:not(.elem) .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) .part.link:not(.elem) .open:active,
  .section:not(.section--darkgreen) .part.link:not(.elem) .open:hover,
  .section:not(.section--darkgreen) .part.link:not(.elem) .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section.section--darkgreen .side .part.link:not(.elem) .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section.section--darkgreen .side .part.link:not(.elem) .open:hover,
.section.section--darkgreen .side .part.link:not(.elem) .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section.section--darkgreen .side .part.link:not(.elem) .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side .part.link:not(.elem) .open:active,
  .section.section--darkgreen .side .part.link:not(.elem) .open:hover,
  .section.section--darkgreen .side .part.link:not(.elem) .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--three .area .seam .part.link:not(.elem) .open,
.section--four .area .seam .part.link:not(.elem) .open,
.section--darkgreen .area:not(.side) .part.link:not(.elem) .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--three .area .seam .part.link:not(.elem) .open:hover,
.section--four .area .seam .part.link:not(.elem) .open:hover,
.section--darkgreen .area:not(.side) .part.link:not(.elem) .open:hover,
.section--three .area .seam .part.link:not(.elem) .open:focus,
.section--four .area .seam .part.link:not(.elem) .open:focus,
.section--darkgreen .area:not(.side) .part.link:not(.elem) .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--three .area .seam .part.link:not(.elem) .open,
  .section--four .area .seam .part.link:not(.elem) .open,
  .section--darkgreen .area:not(.side) .part.link:not(.elem) .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam .part.link:not(.elem) .open:active,
  .section--four .area .seam .part.link:not(.elem) .open:active,
  .section--darkgreen .area:not(.side) .part.link:not(.elem) .open:active,
  .section--three .area .seam .part.link:not(.elem) .open:hover,
  .section--four .area .seam .part.link:not(.elem) .open:hover,
  .section--darkgreen .area:not(.side) .part.link:not(.elem) .open:hover,
  .section--three .area .seam .part.link:not(.elem) .open:focus,
  .section--four .area .seam .part.link:not(.elem) .open:focus,
  .section--darkgreen .area:not(.side) .part.link:not(.elem) .open:focus {
    color: #FFFFFF;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-white.svg');
  }
}
.part.link.tiny {
  width: auto;
  margin-right: 30px;
  margin-bottom: 10px;
}
.section:not(.section--darkgreen) .part.link.elem .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 28px;
  position: relative;
}
.section:not(.section--darkgreen) .part.link.elem .open:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 36px;
  top: 5px;
  left: 0;
  background-size: 13px;
  background-position: 0 -18px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section:not(.section--darkgreen) .part.link.elem .open:hover,
.section:not(.section--darkgreen) .part.link.elem .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section:not(.section--darkgreen) .part.link.elem .open {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) .part.link.elem .open:after {
    content: '';
    background-size: 13px;
    background-position: 0 -15px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) .part.link.elem .open:active,
  .section:not(.section--darkgreen) .part.link.elem .open:hover,
  .section:not(.section--darkgreen) .part.link.elem .open:focus {
    color: #000000;
  }
  .section:not(.section--darkgreen) .part.link.elem .open:active:after,
  .section:not(.section--darkgreen) .part.link.elem .open:hover:after,
  .section:not(.section--darkgreen) .part.link.elem .open:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-black.svg');
  }
}
.section.section--darkgreen .side .part.link.elem .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 28px;
  position: relative;
}
.section.section--darkgreen .side .part.link.elem .open:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 36px;
  top: 5px;
  left: 0;
  background-size: 13px;
  background-position: 0 -18px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section.section--darkgreen .side .part.link.elem .open:hover,
.section.section--darkgreen .side .part.link.elem .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section.section--darkgreen .side .part.link.elem .open {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side .part.link.elem .open:after {
    content: '';
    background-size: 13px;
    background-position: 0 -15px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side .part.link.elem .open:active,
  .section.section--darkgreen .side .part.link.elem .open:hover,
  .section.section--darkgreen .side .part.link.elem .open:focus {
    color: #000000;
  }
  .section.section--darkgreen .side .part.link.elem .open:active:after,
  .section.section--darkgreen .side .part.link.elem .open:hover:after,
  .section.section--darkgreen .side .part.link.elem .open:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-black.svg');
  }
}
.section--three .area .seam .part.link.elem .open,
.section--four .area .seam .part.link.elem .open,
.section--darkgreen .area:not(.side) .part.link.elem .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  padding-left: 28px;
  position: relative;
}
.section--three .area .seam .part.link.elem .open:after,
.section--four .area .seam .part.link.elem .open:after,
.section--darkgreen .area:not(.side) .part.link.elem .open:after {
  content: '';
  position: absolute;
  width: 13px;
  height: 36px;
  top: 5px;
  left: 0;
  background-size: 13px;
  background-position: 0 -18px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-down-green.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--three .area .seam .part.link.elem .open:hover,
.section--four .area .seam .part.link.elem .open:hover,
.section--darkgreen .area:not(.side) .part.link.elem .open:hover,
.section--three .area .seam .part.link.elem .open:focus,
.section--four .area .seam .part.link.elem .open:focus,
.section--darkgreen .area:not(.side) .part.link.elem .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--three .area .seam .part.link.elem .open,
  .section--four .area .seam .part.link.elem .open,
  .section--darkgreen .area:not(.side) .part.link.elem .open {
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam .part.link.elem .open:after,
  .section--four .area .seam .part.link.elem .open:after,
  .section--darkgreen .area:not(.side) .part.link.elem .open:after {
    content: '';
    background-size: 13px;
    background-position: 0 -15px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-down-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam .part.link.elem .open:active,
  .section--four .area .seam .part.link.elem .open:active,
  .section--darkgreen .area:not(.side) .part.link.elem .open:active,
  .section--three .area .seam .part.link.elem .open:hover,
  .section--four .area .seam .part.link.elem .open:hover,
  .section--darkgreen .area:not(.side) .part.link.elem .open:hover,
  .section--three .area .seam .part.link.elem .open:focus,
  .section--four .area .seam .part.link.elem .open:focus,
  .section--darkgreen .area:not(.side) .part.link.elem .open:focus {
    color: #FFFFFF;
  }
  .section--three .area .seam .part.link.elem .open:active:after,
  .section--four .area .seam .part.link.elem .open:active:after,
  .section--darkgreen .area:not(.side) .part.link.elem .open:active:after,
  .section--three .area .seam .part.link.elem .open:hover:after,
  .section--four .area .seam .part.link.elem .open:hover:after,
  .section--darkgreen .area:not(.side) .part.link.elem .open:hover:after,
  .section--three .area .seam .part.link.elem .open:focus:after,
  .section--four .area .seam .part.link.elem .open:focus:after,
  .section--darkgreen .area:not(.side) .part.link.elem .open:focus:after {
    content: '';
    background-size: 13px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-down-white.svg');
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'granville', sans-serif;
  font-weight: normal;
  color: var(--s-textcolor);
  --s-textcolor: #006C43;
}
.section--three .area .seam h1,
.section--three .area .seam h2,
.section--three .area .seam h3,
.section--three .area .seam h4,
.section--three .area .seam h5,
.section--three .area .seam h6,
.section--four .area .seam h1,
.section--four .area .seam h2,
.section--four .area .seam h3,
.section--four .area .seam h4,
.section--four .area .seam h5,
.section--four .area .seam h6,
.section--darkgreen h1,
.section--darkgreen h2,
.section--darkgreen h3,
.section--darkgreen h4,
.section--darkgreen h5,
.section--darkgreen h6 {
  --s-textcolor: #FFFFFF;
}
h1 {
  font-size: 45px;
  line-height: 1;
}
h2 {
  font-size: 35px;
  line-height: 1.14285714;
}
h3 {
  font-size: 25px;
  line-height: 1.2;
}
h4 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
}
h5 {
  font-size: 16px;
  line-height: 1.3125;
  text-transform: uppercase;
  text-decoration: underline;
}
.preTitle {
  margin-bottom: calc(var(--spaceTotal) - 10px);
}
@media (max-width: 1023px) {
  .preTitle {
    margin-top: var(--spaceTotal);
  }
}
p.loud {
  font-size: 20px;
  line-height: 1.4;
  color: var(--s-textcolor);
}
p.norm {
  color: var(--s-textcolor);
}
.section:not(.section--darkgreen) p.pale {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section:not(.section--darkgreen) p.pale:hover,
.section:not(.section--darkgreen) p.pale:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section:not(.section--darkgreen) p.pale {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section:not(.section--darkgreen) p.pale:active,
  .section:not(.section--darkgreen) p.pale:hover,
  .section:not(.section--darkgreen) p.pale:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section.section--darkgreen .side p.pale {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section.section--darkgreen .side p.pale:hover,
.section.section--darkgreen .side p.pale:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section.section--darkgreen .side p.pale {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section.section--darkgreen .side p.pale:active,
  .section.section--darkgreen .side p.pale:hover,
  .section.section--darkgreen .side p.pale:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--three .area .seam p.pale,
.section--four .area .seam p.pale,
.section--darkgreen .area:not(.side) p.pale {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--three .area .seam p.pale:hover,
.section--four .area .seam p.pale:hover,
.section--darkgreen .area:not(.side) p.pale:hover,
.section--three .area .seam p.pale:focus,
.section--four .area .seam p.pale:focus,
.section--darkgreen .area:not(.side) p.pale:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--three .area .seam p.pale,
  .section--four .area .seam p.pale,
  .section--darkgreen .area:not(.side) p.pale {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--three .area .seam p.pale:active,
  .section--four .area .seam p.pale:active,
  .section--darkgreen .area:not(.side) p.pale:active,
  .section--three .area .seam p.pale:hover,
  .section--four .area .seam p.pale:hover,
  .section--darkgreen .area:not(.side) p.pale:hover,
  .section--three .area .seam p.pale:focus,
  .section--four .area .seam p.pale:focus,
  .section--darkgreen .area:not(.side) p.pale:focus {
    color: #FFFFFF;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-white.svg');
  }
}
.section--one .area {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--two .area {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--three .area {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--four .area {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--five .area {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.section--intro--one .side {
  float: left;
  width: 230px;
  height: 230px;
  padding: 10px 10px;
  box-sizing: border-box;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spaceTotal);
  isolation: isolate;
}
.section--intro--one .side:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 100%;
  z-index: -1;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1023px) {
  .section--intro--one .side {
    margin-top: calc(var(--spacePart)*-1);
  }
}
#view .section--intro--one .side {
  transform: rotate(-17deg);
}
#view .section--intro--one .side .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--intro--one .side .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--intro--one .side .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--intro--one .side .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--intro--one .side:hover .part.text p.pale,
  #view .section--intro--one .side:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--intro--one .side:hover .part.text p.pale a,
  #view .section--intro--one .side:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--intro--one .side:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--intro--one .side:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--intro--one .side:hover:after,
.section--intro--one .side:focus:after {
  transform: scale(1.05);
}
#edit .area:not(.side) {
  position: relative;
}
#edit .area:not(.side):before {
  content: '';
  position: absolute;
  background-color: #71caf3;
  padding: 0 5px;
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
}
#edit .section--one .area:before {
  content: 'Bereich 1';
}
#edit .section--two .area:before {
  content: 'Bereich 2';
}
#edit .section--three .area:before {
  content: 'Bereich 3';
}
#edit .section--four .area:before {
  content: 'Bereich 4';
}
#edit .section--five .area:before {
  content: 'Bereich 5';
}
#edit .section--six .area:before {
  content: 'Bereich 6';
}
#edit .section--seven .area:before {
  content: 'Bereich 7';
}
#edit .section--slider .area:before {
  content: 'Slider ';
}
.section--one .area .wide.seam .part {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--one .area .wide.flat,
.section--one .area .slim.edge {
  padding: 184px 20px 0;
  box-sizing: border-box;
  isolation: isolate;
  margin-top: calc(var(--spaceUnit) + var(--spacePart));
  margin-bottom: calc(var(--spaceUnit) + var(--spacePart));
  --s-textcolor: #000000;
}
.section--one .area .wide.flat h1,
.section--one .area .slim.edge h1,
.section--one .area .wide.flat h2,
.section--one .area .slim.edge h2,
.section--one .area .wide.flat h3,
.section--one .area .slim.edge h3,
.section--one .area .wide.flat h4,
.section--one .area .slim.edge h4,
.section--one .area .wide.flat h5,
.section--one .area .slim.edge h5 {
  --s-textcolor: #006C43;
}
.section--one .area .wide.flat .body,
.section--one .area .slim.edge .body {
  padding: 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;
}
.section--one .area .wide.flat .part.link .open,
.section--one .area .slim.edge .part.link .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--one .area .wide.flat .part.link .open:hover,
.section--one .area .slim.edge .part.link .open:hover,
.section--one .area .wide.flat .part.link .open:focus,
.section--one .area .slim.edge .part.link .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--one .area .wide.flat .part.link .open,
  .section--one .area .slim.edge .part.link .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--one .area .wide.flat .part.link .open:active,
  .section--one .area .slim.edge .part.link .open:active,
  .section--one .area .wide.flat .part.link .open:hover,
  .section--one .area .slim.edge .part.link .open:hover,
  .section--one .area .wide.flat .part.link .open:focus,
  .section--one .area .slim.edge .part.link .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--one .area .wide.flat .part.pict.tall:first-child,
.section--one .area .slim.edge .part.pict.tall:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 214px;
  z-index: -1;
}
#view .section--one .area .wide.flat .part.pict.tall:first-child .cb-image-container,
#view .section--one .area .slim.edge .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--one .area .wide.flat .part.pict.tall:first-child .cb-image-container img,
#view .section--one .area .slim.edge .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--one .area .wide.flat .part.link:last-child,
#view .section--one .area .slim.edge .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--one .area .wide.flat .part.link:last-child .open,
#view .section--one .area .slim.edge .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--one .area .wide.flat:hover .part.text p.pale,
  #view .section--one .area .slim.edge:hover .part.text p.pale,
  #view .section--one .area .wide.flat:focus .part.text p.pale,
  #view .section--one .area .slim.edge:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--one .area .wide.flat:hover .part.text p.pale a,
  #view .section--one .area .slim.edge:hover .part.text p.pale a,
  #view .section--one .area .wide.flat:focus .part.text p.pale a,
  #view .section--one .area .slim.edge:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--one .area .wide.flat:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--one .area .slim.edge:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--one .area .wide.flat:focus .part.pict.tall:first-child .cb-image-container img,
  #view .section--one .area .slim.edge:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--one .area .slim.edge .body {
  background-color: #EEF6EA;
}
.section--one .area .slim.flat {
  padding: 184px 20px 0;
  box-sizing: border-box;
  isolation: isolate;
  --s-textcolor: #000000;
}
.section--one .area .slim.flat h1,
.section--one .area .slim.flat h2,
.section--one .area .slim.flat h3,
.section--one .area .slim.flat h4,
.section--one .area .slim.flat h5 {
  --s-textcolor: #006C43;
}
.section--one .area .slim.flat .body {
  padding: 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;
}
.section--one .area .slim.flat .part.link .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--one .area .slim.flat .part.link .open:hover,
.section--one .area .slim.flat .part.link .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--one .area .slim.flat .part.link .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--one .area .slim.flat .part.link .open:active,
  .section--one .area .slim.flat .part.link .open:hover,
  .section--one .area .slim.flat .part.link .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--one .area .slim.flat .part.pict.tall:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 214px;
  z-index: -1;
}
#view .section--one .area .slim.flat .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--one .area .slim.flat .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--one .area .slim.flat .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--one .area .slim.flat .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--one .area .slim.flat:hover .part.text p.pale,
  #view .section--one .area .slim.flat:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--one .area .slim.flat:hover .part.text p.pale a,
  #view .section--one .area .slim.flat:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--one .area .slim.flat:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--one .area .slim.flat:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
#view .section--three .area .unit .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--three .area .unit .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--three .area .unit .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--three .area .unit .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--three .area .unit:hover .part.text p.pale,
  #view .section--three .area .unit:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--three .area .unit:hover .part.text p.pale a,
  #view .section--three .area .unit:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--three .area .unit:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--three .area .unit:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--three .area .seam {
  background-color: #006C43;
  --s-textcolor: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
  margin-top: calc(var(--spaceTotal));
  margin-bottom: calc(var(--spaceTotal));
}
#view .section--three .area .seam .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--three .area .seam .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--three .area .seam .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--three .area .seam .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--three .area .seam:hover .part.text p.pale,
  #view .section--three .area .seam:focus .part.text p.pale {
    color: #FFFFFF;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-white.svg');
  }
  #view .section--three .area .seam:hover .part.text p.pale a,
  #view .section--three .area .seam:focus .part.text p.pale a {
    color: #FFFFFF;
  }
  #view .section--three .area .seam:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--three .area .seam:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
#view .section--four .area .unit .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--four .area .unit .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--four .area .unit .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--four .area .unit .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--four .area .unit:hover .part.text p.pale,
  #view .section--four .area .unit:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--four .area .unit:hover .part.text p.pale a,
  #view .section--four .area .unit:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--four .area .unit:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--four .area .unit:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--four .area .seam {
  margin-top: calc(var(--spaceTotal));
  margin-bottom: calc(var(--spaceTotal));
}
.section--four .area .seam .body {
  background-color: #006C43;
  --s-textcolor: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
}
#view .section--four .area .seam .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--four .area .seam .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--four .area .seam .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--four .area .seam .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--four .area .seam:hover .part.text p.pale,
  #view .section--four .area .seam:focus .part.text p.pale {
    color: #FFFFFF;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-white.svg');
  }
  #view .section--four .area .seam:hover .part.text p.pale a,
  #view .section--four .area .seam:focus .part.text p.pale a {
    color: #FFFFFF;
  }
  #view .section--four .area .seam:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--four .area .seam:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--five .area .wide.pure .part {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--five .area .wide.seam {
  padding: 195px 20px 0;
  box-sizing: border-box;
  isolation: isolate;
  --s-textcolor: #000000;
}
.section--five .area .wide.seam h1,
.section--five .area .wide.seam h2,
.section--five .area .wide.seam h3,
.section--five .area .wide.seam h4,
.section--five .area .wide.seam h5 {
  --s-textcolor: #006C43;
}
.section--five .area .wide.seam .body {
  padding: 20px;
  box-sizing: border-box;
  background-color: #EEF6EA;
}
.section--five .area .wide.seam .part.link .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--five .area .wide.seam .part.link .open:hover,
.section--five .area .wide.seam .part.link .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--five .area .wide.seam .part.link .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--five .area .wide.seam .part.link .open:active,
  .section--five .area .wide.seam .part.link .open:hover,
  .section--five .area .wide.seam .part.link .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--five .area .wide.seam .part.pict.tall:first-child {
  position: absolute;
  top: 0;
  left: -8vw;
  right: 0;
  width: 116vw;
  height: 255px;
  z-index: -1;
}
.section--five .area .wide.seam .part.pict.tall:first-child * {
  height: 100%;
}
.section--five .area .wide.seam .part.pict.tall:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--five .area .slim.pure {
  padding: 264px 0 0;
  box-sizing: border-box;
  isolation: isolate;
  --s-textcolor: #000000;
}
.section--five .area .slim.pure h1,
.section--five .area .slim.pure h2,
.section--five .area .slim.pure h3,
.section--five .area .slim.pure h4,
.section--five .area .slim.pure h5 {
  --s-textcolor: #006C43;
}
.section--five .area .slim.pure .body {
  padding: 20px;
  box-sizing: border-box;
  background-color: #EEF6EA;
}
.section--five .area .slim.pure .part.link .open {
  cursor: pointer;
  display: inline-block;
  color: #8DC585;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  padding-left: 40px;
  background-size: 35px;
  background-position: -10px 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-long-right-green.svg);
}
.section--five .area .slim.pure .part.link .open:hover,
.section--five .area .slim.pure .part.link .open:focus {
  color: #8DC585;
}
@media (min-width: 1024px) {
  .section--five .area .slim.pure .part.link .open {
    padding-left: 47px;
    background-size: 40px;
    background-position: -10px 5px;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-long-right-green.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .section--five .area .slim.pure .part.link .open:active,
  .section--five .area .slim.pure .part.link .open:hover,
  .section--five .area .slim.pure .part.link .open:focus {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
}
.section--five .area .slim.pure .part.pict.tall:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 264px;
  z-index: -1;
}
#view .section--five .area .slim.pure .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .section--five .area .slim.pure .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .section--five .area .slim.pure .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--five .area .slim.pure .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .section--five .area .slim.pure:hover .part.text p.pale,
  #view .section--five .area .slim.pure:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .section--five .area .slim.pure:hover .part.text p.pale a,
  #view .section--five .area .slim.pure:focus .part.text p.pale a {
    color: #000000;
  }
  #view .section--five .area .slim.pure:hover .part.pict.tall:first-child .cb-image-container img,
  #view .section--five .area .slim.pure:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
.section--six .area .unit .part.pict {
  height: 250px;
}
.section--six .area .unit .part.pict * {
  height: 100%;
}
.section--six .area .unit .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--intro--one .side .unit .part.text {
  text-align: center;
}
.section--intro--one .side .unit .part.text .text-section:not(.text-section--pale) * {
  color: #006C43 !important;
}
.section--intro--one .side .unit .part.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--footer .south {
  --spacePart: 5px;
  --spaceUnit: -5px;
}
@media (max-width: 1023px) {
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 29px;
  right: 8%;
  z-index: 2001;
  width: 30px;
  height: 18px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #8DC585;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-layout1:not(.cb-toggle-target-active) .wrapper:not(.cb-scroll-triggered--active) .tline {
  background-color: #FFFFFF;
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-30deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(30deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1998;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  padding: 0 8vw;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #000000;
}
.mobile-navigation div.sub1 {
  margin: 120px 0 30px;
}
.mobile-navigation div.sub1 > .item {
  margin-bottom: 14px;
  padding: 8px 0;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(0, 108, 67, 0.2);
}
.mobile-navigation div.sub1 > .item > .menu {
  padding-right: 30px;
  font-family: 'granville', sans-serif;
  color: #006C43;
  font-weight: normal;
  font-size: 25px;
  line-height: 1.4;
}
.mobile-navigation div.sub2 > .item {
  margin: 5px 0;
}
.mobile-navigation div.sub2 > .item.init {
  margin-top: 10px;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 10px;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-weight: normal;
  color: #8DC585;
  font-size: 18px;
  line-height: 1.44444444;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 15px;
  z-index: 2;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/nav-toggle-open.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  background-image: url(/images/nav-toggle-close.svg);
}
.cb-album {
  position: relative;
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -0.5%;
  margin-bottom: -0.5%;
}
.cb-matrix .part {
  margin-top: 0.5% !important;
  margin-bottom: 0.5% !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 49.5% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 32.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: 24.25% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: 19.2% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: 15.83333333% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: 13.42857143% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: 11.625% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-matrix .scan td a {
  color: var(--s-textcolor);
}
.cb-matrix .scan td .same {
  color: #8DC585;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.33802817%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1023px) {
  .cb-slideshow .part.foto,
  .cb-slideshow .part.crossslideContainer {
    padding-bottom: 70%;
  }
}
@media (max-width: 767px) {
  .cb-slideshow .part.foto,
  .cb-slideshow .part.crossslideContainer {
    padding-bottom: 120%;
  }
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
@media (max-width: 1023px) {
  .cb-slideshow .head {
    bottom: unset;
    top: 60%;
  }
}
@media (max-width: 767px) {
  .cb-slideshow .head {
    top: 50%;
  }
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  width: 30px;
  height: 50px;
  cursor: pointer;
  z-index: 100;
  bottom: 0;
  left: 60px;
  transform: rotate(-90deg);
}
.cb-slideshow .ctrl a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 38px;
  background-size: 22px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-scrollup.svg);
}
.cb-slideshow .ctrl a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 35px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-scrollup.svg);
}
@media (min-width: 1024px) {
  .cb-slideshow .ctrl a {
    width: 50px;
    height: 90px;
  }
  .cb-slideshow .ctrl a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 63px;
    background-size: 22px;
    background-position: 50% calc(100% + 15px);
    background-repeat: no-repeat;
    background-image: url(/images/icon-arrow-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  .cb-slideshow .ctrl a:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 40px;
    height: 46px;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/icon-scrollup.svg);
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
    transform-origin: 22%;
  }
  .cb-slideshow .ctrl a:hover:after,
  .cb-slideshow .ctrl a:focus:after {
    background-position: 50% 100%;
  }
  .cb-slideshow .ctrl a:hover:before,
  .cb-slideshow .ctrl a:focus:before {
    transform: rotate(-160deg);
  }
}
@media (max-width: 1023px) {
  .cb-slideshow .ctrl a {
    left: 30px;
  }
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
  width: 50px;
}
.cb-slideshow .ctrl .next a {
  left: unset;
  right: 60px;
  transform: rotate(90deg);
}
@media (max-width: 1023px) {
  .cb-slideshow .ctrl .next a {
    right: 30px;
  }
}
.cb-slideshow .cb-image-caption {
  position: absolute;
  bottom: 30px;
  z-index: 2;
  padding: 0 210px;
  box-sizing: border-box;
  font-weight: normal;
  color: white;
}
@media (max-width: 1023px) {
  .cb-slideshow .cb-image-caption {
    background-color: #006C43;
    padding: 20px 30px;
    top: 70%;
    bottom: unset;
    height: 30%;
  }
}
@media (max-width: 767px) {
  .cb-slideshow .cb-image-caption {
    background-color: #006C43;
    padding: 20px 20px;
    top: 50%;
    bottom: unset;
    height: 50%;
  }
}
.cb-slideshow .cb-image-container:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 310px;
  z-index: 1;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to top, #006C43, rgba(0, 108, 67, 0));
}
@media (max-width: 1023px) {
  .cb-slideshow .cb-image-container:after {
    height: 10%;
  }
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
.slider__width {
  float: left;
  width: 100%;
}
#view .slider .area .unit .part.pict.tall:first-child .cb-image-container {
  overflow: hidden;
}
#view .slider .area .unit .part.pict.tall:first-child .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view .slider .area .unit .part.link:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .slider .area .unit .part.link:last-child .open {
  float: left;
  height: 100%;
  width: 100%;
  background-image: unset;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  #view .slider .area .unit:hover .part.text p.pale,
  #view .slider .area .unit:focus .part.text p.pale {
    color: #000000;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    background-image: url('/images/arrow-long-right-black.svg');
  }
  #view .slider .area .unit:hover .part.text p.pale a,
  #view .slider .area .unit:focus .part.text p.pale a {
    color: #000000;
  }
  #view .slider .area .unit:hover .part.pict.tall:first-child .cb-image-container img,
  #view .slider .area .unit:focus .part.pict.tall:first-child .cb-image-container img {
    transform: scale(1.1);
    opacity: 1;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .slider {
    float: left;
    width: 100vw;
    margin-left: -8vw;
  }
  .slider__width {
    float: left;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-left: 8vw;
    box-sizing: border-box;
  }
  .slider__width::-webkit-scrollbar {
    display: none;
  }
  .slider .area {
    margin-top: var(--spaceTotal);
    margin-bottom: var(--spaceTotal);
  }
  #view .slider .area {
    width: auto;
    display: flex;
    align-items: flex-start;
  }
  .slider .area .unit {
    width: 70vw;
    min-height: 365px;
    margin-right: 30px;
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  #view .slider .area .unit:last-child {
    margin-right: 8vw;
  }
  .slider__index {
    position: relative;
    float: left;
    left: 0;
    bottom: -25px;
    z-index: 3;
    cursor: pointer;
  }
  .slider-index {
    float: left;
    margin: 0 5px;
    width: 30px;
    height: 4px;
    background-color: #8DC585;
    cursor: pointer;
  }
  .slider-index.slider-index--active {
    position: absolute;
    background-color: #006C43;
    width: 30px!important;
    height: 4px;
  }
  .slider-index:first-child {
    margin-top: 0;
  }
  .slider-index:last-child {
    margin-bottom: 0;
  }
}
/*# sourceMappingURL=./screen-small.css.map */